import React, { useEffect, useState, useMemo } from "react";
//import { deleteDB } from 'idb';
import { StandardTable } from "../components/StandardTable";
import { getApiAxios, getAxiosErrorDisplay, tableFormatDate, tableFormatDateTime, statusToString } from '../helpers/misc.js';
import ErrorBoundary from "../components/ErrorBoundary"
import CreateOrUpdateOrganization from "../components/CreateOrUpdateOrganization";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import ConfirmAction from "../components/ConfirmAction";

const Organizations = () => {

    const [data, setData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [showEditForm, setShowEditForm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [actionError, setActionError] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const selectedRowIds = useMemo(() => { return [] }, [])

    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const result = await getApiAxios().get(`/organizations/?names=true`)
                setData(result.data)
                setActionError(null)
            } catch (error) {
                setActionError("Failed to load data from server. " + error.message)
                console.error(error);
            }
            setIsLoading(false)
        }
        fetchData();

    }, [refreshData])


    const refresh = () => {
        setRefreshData(!refreshData)
    }

    const handleAddButton = () => {
        setShowEditForm("add")
    }

    const onEditDialogExit = (data) => {
        setShowEditForm(null)
        if (data) {
            refresh()
        }
    }

    const onDeleteConfirmExit = async (isConfirmed) => {
        setShowDeleteConfirm(false)
        if (isConfirmed) {
            const ids = selectedRowIds.current

            if (ids.find(id => id === "00000000-0000-0000-0000-000000000000")) {
                setActionError("Error while deleting: you cannot delete the admin organization")
                return
            }

            try {
                await getApiAxios().post("organizations/actions", { actions: [{ action: "delete", ids: ids }] })
                setActionError(null)
                refresh()
            }
            catch (error) {
                setActionError("Error while deleting: " + await getAxiosErrorDisplay(error))
            }
        }
    }

    const columns = useMemo(() =>
        [
            { Header: "Name", accessor: "name", },
            { Header: "Comments", accessor: "description", },
            { Header: "Status", accessor: (row) => statusToString(row.status), disableGlobalFilter: true },
            { Header: "License", accessor: "license", },
            { Header: "Created", accessor: (row) => tableFormatDate(row.createdAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_createdByName", },
            { Header: "Updated", accessor: (row) => tableFormatDateTime(row.updatedAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_updatedByName", },
        ],
        []
    );

    const tableOptions = {
        uniqueRowIdName: "_id",
        sortBy: "name",
        onRowClicked: (rowId, /*currentRowData*/) => {
            setShowEditForm(data.find(d => d._id === rowId))
        },
        onDeleteRequest: async (_selectedRowIds) => {
            selectedRowIds.current = _selectedRowIds
            setShowDeleteConfirm(true)
        },

        searchContainerId: "tableFilterContainer",
        deleteIconContainerId: "deleteIconContainer",
        isLoading: data === null
    }

    return (
        <ErrorBoundary>
            <>
                <PageHeader title="Organizations" onCreateClicked={handleAddButton}
                    lastActionError={actionError}
                    addLeftSlot={
                        <>
                            <div id="deleteIconContainer" />
                        </>
                    }
                    addRightSlot={
                        <div id="tableFilterContainer" />
                    }
                    onRefreshClicked={refresh}

                />

                <StandardTable columns={columns} initData={data || []} options={tableOptions} />
                {showEditForm !== null &&
                    <CreateOrUpdateOrganization show={showEditForm !== null} isUpdate={showEditForm !== "add"}
                        initData={showEditForm === "add" ? { status: 1, license: "demo" } : showEditForm}
                        onExit={onEditDialogExit} />
                }
                {showEditForm !== null &&
                    <CreateOrUpdateOrganization show={showEditForm !== null} isUpdate={showEditForm !== "add"}
                        initData={showEditForm === "add" ?
                            {
                                status: 1, //orgId: ctx.auth.orgId,
                            }
                            : showEditForm}
                        onExit={onEditDialogExit}
                    />
                }

                <ConfirmAction message="You requested to delete one or more organizations. All users and projects that belong to them will be deleted, too." okButtonText="Delete"
                    show={showDeleteConfirm} onConfirm={onDeleteConfirmExit} />
                {isLoading && <Spinner text="" />}
            </>
        </ErrorBoundary>
    )
}

export default Organizations;