class Roles {

    // roles are bitwise encoded into a single numeric value. Use values as 1, 2, 4, 8, 16....
    // 2 roles should not have same value, unless if needed to solve some backward compability issues
    //EVERY role should be declared as constant AND added to definedFlags

    static sysAdmin = 1 << 0
    static orgAdmin = 1 << 1
    static orgSeeAll = 1 << 2
    static user = 1 << 3
    static _all = 0xffffffff

    static #definedFlags = [
        { flag: this.sysAdmin, name: "sysAdmin", desc: 'system admin' },
        { flag: this.orgAdmin, name: "orgAdmin", desc: 'organization admin' },
        { flag: this.orgSeeAll, name: "orgSeeAll", desc: 'sees all projects in organization' },
        { flag: this.user, name: "user", desc: 'User' },
    ]

    static #roleAssignmentRights = [
        { by: this.sysAdmin, allowed: this._all },
        { by: this.orgAdmin, allowed: this.user | this.orgAdmin | this.orgSeeAll }
    ]

    #currentFlags = 0

    constructor(parms) {
        if (parms === undefined) // parameter was omitted in call
            this.setFromDBValue(0)

        else if (parms instanceof Array) {  // cant use typeof here, would return 'object'
            this.setFromRoles(parms)
        }

        else if (!isNaN(parms)) {
            this.setFromDBValue(parms)
        }
        else {
            // don't throw from the constructor???
            throw new Error("Roles contructor parms must be either empty, a numercial value or an array of role constants")

        }
    }

    get dbValue() {
        return this.#currentFlags;
    }

    static getDescription(role) {
        const def = this.#definedFlags.find(e => e.flag === role);
        return def?.desc
    }

    setFromDBValue(dbNumValue) {
        if (/*!dbNumValue ||*/ isNaN(dbNumValue)) {
            throw new Error("setFromDBValue dbNumValue must be a valid number")
        }
        this.#currentFlags = dbNumValue
    }

    setFromRoles(roleArray) {
        let f = 0;
        roleArray.forEach(element => { f = f | element })
        this.#currentFlags = f
    }

    includes(role) {
        return (role & this.#currentFlags) !== 0
    }

    includesAny(roleArray) {
        for (let i = 0; i < roleArray.length; i++) {
            if (this.includes(roleArray[i]))
                return true
        }
        return false
    }

    includesAll(roleArray) {
        for (let i = 0; i < roleArray.length; i++) {
            if (!this.includes(roleArray[i]))
                return false
        }
        return true
    }

    add(role) {
        this.#currentFlags = this.#currentFlags | role//.flag
    }

    remove(role) {
        let r = this.#currentFlags & (~role/*.flag*/)
        this.#currentFlags = r
    }

    getRoleAssignmentRights() {
        let flags = 0
        Roles.#roleAssignmentRights.forEach(ccc => {
            if (ccc.by & this.#currentFlags) {
                flags |= ccc.allowed
            }
        })
        return flags
    }

    getRoleAssignmentRightsAsRole() {
        return new Roles(this.getRoleAssignmentRights())
    }

    toString() {
        let s = ""
        Roles.#definedFlags.forEach(e => { if (this.#currentFlags & e.flag) s += e.name + ", " })

        if (s.length > 0)
            s = s.slice(0, -2) // remove trailing ', '

        return s
    }

    toStringArray() {
        let s2 = []
        Roles.#definedFlags.forEach(e => { if (this.#currentFlags & e.flag) s2.push(e.name) })
        return s2
    }

    toConstArray(optionalRoles) {
        let s2 = []
        const r = optionalRoles || this.#currentFlags
        Roles.#definedFlags.forEach(e => { if (r & e.flag) s2.push(e.flag) })
        return s2
    }

    toObjectArray() {
        let s2 = []
        Roles.#definedFlags.forEach(e => { if (this.#currentFlags & e.flag) s2.push({ const: e.flag, name: e.name, desc: e.desc }) })
        return s2
    }

}

export default Roles // Export class


/*
           console.log("Roles",Roles) //Roles [class Roles] { sysAdmin: 1, orgAdmin: 2, orgSeeAll: 4 }
            
             console.log ("sysAdmin:",Roles.getDescription(Roles.sysAdmin)) // 'system admin'
            
            let  roles = new Roles(777)
            console.log("dbValue", roles.dbValue)   //777

            roles = new Roles([Roles.sysAdmin])
            console.log("dbValue", roles.dbValue)   //1
 
            
            roles.setFromDBValue(999)
            console.log("dbValue", roles.dbValue)       //999

            roles.setFromRoles([Roles.sysAdmin, Roles.orgSeeAll])
            console.log("dbValue", roles.dbValue)       // 5
            console.log("asString:", roles.asString())  // 'sysAdmin, orgSeeAll'
            console.log("asArray:", roles.asArray())    //[ 'sysAdmin', 'orgSeeAll' ]
 

            console.log("includes", roles.includes(Roles.sysAdmin)) //true
            console.log("includes", roles.includes(Roles.orgAdmin)) // false
            console.log("includesAny", roles.includesAny([Roles.sysAdmin, Roles.orgAdmin])) // true
            console.log("includesAny", roles.includesAny([Roles.orgAdmin])) //false
      
            roles.remove(Roles.sysAdmin)
            console.log("removed", roles.dbValue)   //4
            roles.add(Roles.sysAdmin)
            console.log("added", roles.dbValue)     //5
  
*/
