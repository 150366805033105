import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { IconEyeOpen, IconEyeClosed, IconCredentials } from './Icons';
import { getApiAxios /*, getAxiosErrorDisplay*/ } from '../helpers/misc';
// eslint-disable-next-line
import { Button, Form, Row, /*Col,*/ Container, Alert, InputGroup, FloatingLabel } from 'react-bootstrap';
import { createAuthForSessionContext, setStoredToken, setStoredRefreshToken, getJWTTokenPayload } from "../helpers/auth"
import useSession from "../hooks/useSession"

export default function LoginComponent({ stayOnPage }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [errorMsg, setErrorMsg] = useState(null);
    //let success = false
    //eslint-disable-next-line
    const { session: ctx, setSession: setCtx } = useSession()

    const navigate = useNavigate()

/*    async function loginApi(credentials) {
        try {
            const resp = await getApiAxios().post("/login", credentials)
            //success = true
            return { token: resp.data.token, refreshToken: resp.data.refreshToken }
        }
        catch (error) {
            setErrorMsg(await getAxiosErrorDisplay(error))
            return {}
        }
    }
*/

    const loginToBackend = async credentials => {
        setErrorMsg(null)
 
        try {
            const resp = await getApiAxios().post("/login", credentials)
            //return { token: resp.data.token, refreshToken: resp.data.refreshToken }
            setStoredToken(resp.data.token)
            setStoredRefreshToken(resp.data.refreshToken)
            setCtx({ auth: createAuthForSessionContext(resp.data.token) })

            if (!stayOnPage)
                navigate("/")
        }
        catch (error) {
            setErrorMsg(error._dispMsg)
        }
   }

    const handleSubmit = async e => {
        e.preventDefault();
        await loginToBackend({ email: email, password: password })
    }
/*
    const handleSubmitORG = async e => {
        setErrorMsg(null)
        e.preventDefault();
        const { token, refreshToken } = await loginApi({
            email: email,
            password: password
        });

        if (token) {
            setStoredToken(token)
            setStoredRefreshToken(refreshToken)
            setCtx({ auth: createAuthForSessionContext(token) })

            if (!stayOnPage)
                navigate("/")
        }
    }
*/
    const handleGoogleAuthSuccess = async (response) => {
        // returned response.credential is a JWT token
        console.log("google credential:", response.credential);
        const payload = getJWTTokenPayload(response.credential)
        console.log("google payload:", payload);
        await loginToBackend({googleAuthToken: response.credential})
    };

    const handleGoogleAuthError = (error) => {
        console.log("google error",error);
        setErrorMsg("Google authentication failed: " + error)
    };

    return (
        <>
            <Row className="align-items-center" style={{ height: "100vh" }}>
                <Row className="border shadow-lg p-3 mb-5 bg-body rounded  mx-auto col-10 col-md-8 col-lg-4">
                    <h5>Please sign in</h5>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <InputGroup>
                                <InputGroup.Text className='ps-2 pe-1' ><span style={{ marginRight: "3px" }} >@</span> </InputGroup.Text>
                                <Form.Control onChange={e => setEmail(e.target.value)} type="email" placeholder="Email address" autoFocus={true} />
                                {/* <Form.Text className="text-muted"> We'll never share your email with anyone else.</Form.Text>*/}
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                            <InputGroup>
                                <InputGroup.Text className='ps-2 pe-1'><IconCredentials /></InputGroup.Text>
                                <Form.Control onChange={e => setPassword(e.target.value)} placeholder="Password" type={passwordVisible ? "" : "password"} />
                                <InputGroup.Text className='ps-2 pe-1' onClick={e => setPasswordVisible(!passwordVisible)}>
                                    {passwordVisible ? <IconEyeOpen clickable /> : <IconEyeClosed clickable />}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>


                        {/* 
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group>
                        */}

                        <Button variant="btn btn-primary" size='sm' type="submit">
                            Submit
                        </Button>
                        <hr/>
                        <GoogleLogin 
                            size="medium"
                            
                            onSuccess={handleGoogleAuthSuccess} onError={handleGoogleAuthError} 
                            cookiePolicy={'single_host_origin'} />

                        {errorMsg &&
                            <>
                                <br /><br />
                                <Alert variant="danger">
                                    {errorMsg}
                                </Alert>
                            </>
                        }
                    </Form>
                </Row>
            </Row>
        </>
    )
}

LoginComponent.propTypes = {
    //   setToken: PropTypes.func.isRequired
};

/*
                      <InputGroup>
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Group controlId="formEmail">
                                <FloatingLabel controlId="formEmail" label="Email">
                                    <Form.Control onChange={e => setEmail(e.target.value)} type="email" placeholder="Your email" />
                                </FloatingLabel>
                                 </Form.Group>
                                </InputGroup>
        



         {0 &&
                <Container >

                    <Row className="justify-content-center">
                        <h2>Please sign in2222</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
                                <Form.Label column >Email</Form.Label>

                                <Col sm={10}>
                                    <Form.Control onChange={e => setEmail(e.target.value)} style={{ maxWidth: "25em" }} type="email" placeholder="Email" />
                                </Col>
                             </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">
                                <Form.Label column >Password</Form.Label>
                                <Col sm={10}>
                                    <Form.Control onChange={e => setPassword(e.target.value)} style={{ maxWidth: "25em" }} type="password" placeholder="Password" />
                                </Col>
                            </Form.Group>


                            <Button variant="primary" type="submit">
                                Submit
                            </Button>

                            {errorMsg &&
                                <>
                                    <br /><br />
                                    <Alert bsStyle="danger" variant="danger">
                                        <xxxstrong>{errorMsg}</xxxstrong>
                                    </Alert>
                                </>
                            }
                        </Form>
                    </Row>
                </Container>*/