//import Cookies from 'universal-cookie';
import { Buffer } from 'buffer';
import { broadcastAction } from './broadcast';
import { getApiAxios } from "./misc";
import * as Sentry from "@sentry/browser";


/* see discussion in /docs/auth */

export const authOptions = {
    idleBeforeLogoutSec: 2 * 60 * 60,   // if user is idle longer that , he will be logged out
    //pollForTokenRefreshSec: 60,       // how often to check for need to refresh token (actual refresh will be only attempted when more than half of the token lifetime is used)
    pollForTokenRefreshSec: 60,         // how often to check for need to refresh token (actual refresh will be only attempted when more than half of the token lifetime is used)
    refreshTokenAfterPercentage: 0.5    // 0.5     > 0, < 1, if remaining token lifetime falls below refreshTokenAfterPercentage of it: call refresh token api
}

export const setStoredToken = (userToken) => {

    localStorage.setItem('token', userToken);

    /*cookies.set("token", userToken, {
        path: "/",
        //httpOnly: true, will not work, since not run on web server
        //secure: process.env.NODE_ENV === "development" ? false : true
        //secure: true
    })
    */
}

export const setStoredRefreshToken = (refreshToken) => {
    localStorage.setItem('refreshToken', refreshToken);
}

export const getStoredToken = () => {
    //const token = cookies.get("token")

    let token = localStorage.getItem('token');
    if (!token)
        token = null // need null instead undefined
    return token
}

export const getStoredRefreshToken = () => {
    //const token = cookies.get("refreshToken")

    let refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken)
        refreshToken = null // need null instead undefined
    return refreshToken
}

export const removeStoredToken = () => {
    localStorage.removeItem('token');
    //cookies.remove("token")
}

export const removeStoredRefreshToken = () => {
    localStorage.removeItem('refreshToken');
    //cookies.remove("refreshToken")
}

export function getJWTTokenPayload(token) {
    // no need for lengthy crypto, the payload is always in clear text, though in base64, by design.
    // in case that the server signed the JWT with a private key (instead secret) we COULD do verification 
    // using the public key, to test if the token has not been tempered with in the token storage.
    // but in react, malicious user has full code access and can just modify runniong code to circumvent that check.

    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

export function getJWTTokenPayloadLean(token) {
    // JWT std in its wisdom puts additional parameters like expire date etc into the payload

    const payload = getJWTTokenPayload(token)
    delete payload.iss;
    delete payload.iat;
    delete payload.exp;
    delete payload.nbf;
    delete payload.jti;
    delete payload.jwtid;

    return payload
}

export const getStoredTokenIfNotExpired = () => {

    const token = getStoredToken()

    if (token) {
        const payload = getJWTTokenPayload(token)
        //console.log("payload", payload)

        // even if called just before an API call, the token COULD expire whill request still in transit, so give a short gracetime
        const graceTime = 5 * 1000 //msec

        if (Date.now() >= payload.exp * 1000 + graceTime) {
            console.log("expired")
            return null
        }

        return token
    }
    else
        return null
}

export const refreshTokenIfNeeded = async () => {
    // while the user was not logged out because of being idle, check if the API token should be refreshed

    // returns true if authentication/authorization state changed
    // a. the renew resulted returned different user properties than the last ones,
    // b. user has been deleted or he or his org were disabled
    // c. the refresh token is expired (could happen in very rare scenarios, see auth document)

    const token = getStoredToken()
    const refreshToken = getStoredRefreshToken()

    if (!token || !refreshToken)
        return true //false

    const tokenPayload = getJWTTokenPayload(token)

    const now = Date.now()
    // exp/iat are epoch time but in seconds (/1000)
    const secondsLeft = tokenPayload.exp - now / 1000
    const tokenLifeTime = tokenPayload.exp - tokenPayload.iat
 
    if (secondsLeft >= tokenLifeTime * authOptions.refreshTokenAfterPercentage)
        // nothing to do for now
        return false

    const refreshTokenPayload = getJWTTokenPayload(refreshToken)
    const refreshSecondsLeft = refreshTokenPayload.exp - now / 1000
    if (refreshSecondsLeft <= 0) {
        // unrevoverable, refresh token expired. user should see login screen. Should never happen unless server was down for the lifetime of the refresh token
        logout(true)
        const err = new Error("RefreshTokenIfNeeded: could not refresh token since refresh token expired ")
        console.log(err)
        Sentry.captureException(err)
        return true
    }

    try {
        console.log("refreshTokenIfNeeded needed", new Date().toTimeString())
        const rs = await getApiAxios().post("/refreshtoken", { refreshToken: refreshToken })

        const { token: newToken, refreshToken: newRefreshToken } = rs.data;
        setStoredToken(newToken)
        setStoredRefreshToken(newRefreshToken)

        // detect if the payload changed, ignoring expiration and other parms

        const oldStr = JSON.stringify(getJWTTokenPayloadLean(token))
        const newStr = JSON.stringify(getJWTTokenPayloadLean(newToken))
        return oldStr !== newStr
    }
    catch (error) {
        // either this auto refresh mechanism does not work properly or api server is down (refresh token expired)
        // or our API detected that the user is not authorized anymore (deleted/disabled?)

        if (!error?.response?.data) // network or other non-api error?
            return false

        const errorCode = error.response.data.errCode
        if ([1, 2].includes(errorCode)) {
            // refresh failed because user or org disabled or deleted. force logout
            logout(true)
            return true
        }

        const err = new Error("RefreshTokenIfNeeded: could not refresh token ", { cause: error })
        console.log(err)
        Sentry.captureException(err)
        return false
    }
}

export const createAuthForSessionContext = (token, withoutBroadcast) => {

    let authctx = {}
    if (token) {
        //setStoredToken(token) moved to login
        if (!withoutBroadcast)
            broadcastAction('loggedin')
        const payload = getJWTTokenPayload(token)
        authctx = {
            userId: payload.id,
            userName: payload.name,
            orgId: payload.orgId,
            orgName: payload.orgName,
            roles: payload.roles,
        }
    }
    else
        authctx = null

    return authctx
}

export const logout = (withoutBroadcast) => {
    removeStoredToken()
    removeStoredRefreshToken()
    if (!withoutBroadcast)
        broadcastAction('loggedout')
}





