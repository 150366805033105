import PropTypes from 'prop-types'
import { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { getApiAxios, getAxiosErrorDisplay } from '../helpers/misc';

const CreateOrUpdateProject = ({ isUpdate, initData, show, onExit, ...props }) => {


    const [errorMsg, setErrorMsg] = useState("")
    const [showValidation, setShowValidation] = useState(false);
    const data = useRef({ ...initData });

    const dataChanged = (name, value) => {
        data.current[name] = value
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setShowValidation(true)
            return;
        }
        else
            setShowValidation(false)

        try {
            if (!isUpdate)
                await getApiAxios().post('/projects', data.current)
            else
                await getApiAxios().put('/projects/' + initData._id, data.current)

            setErrorMsg("")
            onExit(data.current)
        }
        catch (error) {
            event.preventDefault();
            event.stopPropagation();

            console.error("editproj api", error);
            setErrorMsg(await getAxiosErrorDisplay(error))
        }
    }

    const handleCancel = () => {
        setShowValidation(false);
        setErrorMsg("")
        onExit(null)
    }

    return (
        <Modal show={show} centered  >
            <Form id="theForm" noValidate validated={showValidation} onSubmit={handleSubmit}>
                <Modal.Header >
                    <Modal.Title>{isUpdate ? "Update project" : "Create new project"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control required type="text" placeholder="Project name" defaultValue={initData.name}
                                onChange={(e) => dataChanged("name", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control placeholder="Description" defaultValue={initData.description}
                                onChange={(e) => dataChanged("description", e.target.value)} />
                        </Form.Group>
                    </Row>

                    <Row className="">
                        <Form.Group >
                            <Form.Check
                                required
                                label="Data will be stored on the EmpRoute server"
                                name="group1"
                                type="radio"
                                id="radioServer"
                                defaultChecked={initData.dataStorageType === 1}
                                onChange={(e) => e.target.checked && dataChanged("dataStorageType", 1)} />

                            <Form.Check
                                required
                                label="Data will be stored locally in user's browser"
                                name="group1"
                                type="radio"
                                id="radioLocal"
                                defaultChecked={initData.dataStorageType === 2}
                                onChange={(e) => e.target.checked && dataChanged("dataStorageType", 2)}
                            />
                        </Form.Group>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    {
                        errorMsg &&
                        <div className='me-auto'>
                            <span className='text-danger fw-bold'>Operation failed: </span>
                            <span className='text-danger'> {errorMsg}</span>
                        </div>
                    }
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" type="submit"  >Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>

    )

}

export default CreateOrUpdateProject

CreateOrUpdateProject.propTypes = {
    show: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onExit: PropTypes.func.isRequired,
};


