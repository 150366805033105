const env = {
    //read from .env etc goes here
}

/*
    in any code you can set a message to be shown in the GlobalMsg component, next time that it is rendered
    Why expire time? Initially I tried in GlobalMsg to null the message, once displayed. The
    side effects were
        - in Strictmode, with its double rendering, the second render would see the alreay nulled message
        - if the msg was set, then the sessionState changed and then a RequireAuth caused redirect
            the messgage was reset after the rerendering of the instance and then navigation to login occured.
            The message was consumed(nulled) during the initially rerender but the login page did not find a msg anymore
    Expire time solves this in a crude way: Unless specified otherwise, a new message will expire after
    2 seconds. GlobalMsg will not see an expired message
    
*/
function Msg() { }
Msg.msgToDisplay = null // "something" // set as static
Msg.expiresAt = null // set as static


const setMsgToDisplay = (msg, expiresAfterSec = 2) => {
    Msg.msgToDisplay = msg

    //expiresAfterSec = expiresAfterSec || 2
    let expiresAt = new Date()
    expiresAt.setSeconds(expiresAt.getSeconds() + expiresAfterSec)
    Msg.expiresAt = expiresAt
}

const getMsgToDisplay = () => {
    if (!Msg.expiresAt || Msg.expiresAt >= new Date())
        return Msg.msgToDisplay
    else
        return null

}

export { env, setMsgToDisplay, getMsgToDisplay }
