import { Alert } from 'react-bootstrap';
import { getMsgToDisplay /*, setMsgToDisplay*/ } from "../helpers/globalVars";

/* Warning: As implemented, this will not show anything in UI if running in StrictMode
AND if run under development. 
Strictmode causes double rendering (only in development mode). The first one clears the 
message and the second does not see the message anymore

use useMemo!!!
*/

const GlobalMsg = (/*{ globalFilter, setGlobalFilter}*/) => {
    let msg = getMsgToDisplay()
    //console.log("xxx GlobalMsg", msg)
  
    return (
    
        msg && 
            <Alert variant="info">{msg}</Alert>
        
    )
}




export { GlobalMsg }