import { Outlet, Link, useNavigate } from "react-router-dom";
// eslint-disable-next-line
import { Navbar, Container, Nav, NavDropdown, NavItem, } from 'react-bootstrap';///Nav';
import ErrorBoundary from "../components/ErrorBoundary"
import useSession from "../hooks/useSession"
import { logout } from '../helpers/auth'
import { GlobalMsg } from "../components/GlobalMsg.js"
import Roles from "../helpers/roles"
import packageJson from "../../package.json"

const Layout = () => {

  //const {ctx, setCtx} = useContext(AuthContext)
  // const ctx = useContext(SessionContext).session

  const { session: ctx, setSession: setCtx } = useSession()
  //console.log("ctx layout", ctx)

  const roles = new Roles(ctx && ctx.auth && ctx.auth.roles)
  //console.log("layout includes sysAdmin", roles.includesAll([Roles.sysAdmin]))


  const navigate = useNavigate();

  function onLogoutClick(e) {
    e.preventDefault();
    logout(false)
    // clear user session context
    const newCtx = ctx;
    newCtx.auth = null

    //console.log("newCtx layout after signout", newCtx)

    //ctx.setAll(newCtx)
    setCtx(newCtx)
    navigate("/")
  }

  return (

    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" className="py-0">
        <Navbar.Brand  className="ms-1">EmpRoute
          <img className="ms-1" src="/route.png" alt="" style={{ maxHeight: '1em' }} title={"Version "+packageJson.version}  ></img>
        </Navbar.Brand>

        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              {roles.includesAny([Roles.sysAdmin, Roles.orgAdmin, Roles.user]) &&
                <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
              }
              {roles.includesAny([Roles.sysAdmin]) &&
                <Nav.Link as={Link} to="/organizations">Organizations</Nav.Link>
              }
              {roles.includesAny([Roles.sysAdmin, Roles.orgAdmin]) &&
                <Nav.Link as={Link} to="/users">Users</Nav.Link>
              }
              {roles.includesAny([Roles.sysAdmin,]) &&
                <NavDropdown title="System" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/organizations">Organizations</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/users">Users</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated Item</NavDropdown.Item>
                </NavDropdown>
              }
            </Nav>
            <Nav>
              {ctx.auth &&
                <>
                  <Nav.Link as={Link} to="/" onClick={onLogoutClick} >Sign off</Nav.Link>
                  <NavDropdown title={ctx.auth.userName} id="collasible-nav-dropdown" menuVariant="dark">
                    <NavDropdown.Item as={Link} to="/usersettings">Settings</NavDropdown.Item>
                  </NavDropdown>
                </>
              }
              {!ctx.auth &&
                <Nav.Link as={Link} to="/login">Sign in</Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="ms-2 me-2 mt-2">
        <GlobalMsg />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  )
};

export default Layout;