//import logo from './logo.svg';
//import React from 'react';
//import { lazy, Suspense } from "react";

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, } from "react-router-dom"
//import { GoogleOAuthProvider } from '@react-oauth/google';
//import { RecoilRoot/*, atom,  selector,  useRecoilState,  useRecoilValue*/ } from 'recoil'
//import ProtectedRoute from './components/ProtectedRoute'
import { SessionProvider } from './context/SessionContext.js'
import RequireAuth from './components/RequireAuth'
import Roles from "./helpers/roles"

import "bootstrap/dist/js/bootstrap.bundle.min";
//import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import "./scss/custom.scss"
//import "./bootstrap.min.css" // created in https://bootstrap.build/app, works regarding font size and colors, but strange side effects, for example login form. seems that react-bootstrap works different from std bootstrap, see his bs-... vars in root

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Organizations from "./pages/Organizations";
import Users from "./pages/Users";
//import Project from "./pages/Project";
import Projects from "./pages/Projects";
import NoPage from "./pages/NoPage";
import Login from './pages/Login';


export default function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route element={<RequireAuth allowedRoles={[Roles.sysAdmin]} />}>
          <Route exact path="organizations" element={<Organizations />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[Roles.sysAdmin, Roles.orgAdmin]} />}>
          <Route exact path="users" element={<Users />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[Roles.sysAdmin, Roles.user, Roles.orgAdmin]} />}>
          <Route exact path="projects" element={<Projects />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[Roles.sysAdmin, Roles.user, Roles.orgAdmin]} />}>
          <Route exact path="project" lazy={() => import("./pages/ProjectLoader.js")} />
        </Route>

        <Route exact path="login" element={<Login />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    )
  );
  //const Project = lazy(() => import('./pages/Project'));


  return (

    <SessionProvider >
      {/*<RecoilRoot>*/}
      <RouterProvider router={router} />
      {/*</RecoilRoot>*/}
    </SessionProvider>
  );
}





