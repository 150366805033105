import React, { useEffect, useState, useMemo, } from "react";
import { useNavigate } from "react-router-dom";
//import { deleteDB } from 'idb';
import { StandardTable } from "../components/StandardTable";
import { getApiAxios, getAxiosErrorDisplay, tableFormatDate, tableFormatDateTime, } from '../helpers/misc.js';
import ErrorBoundary from "../components/ErrorBoundary"
import CreateOrUpdateProject from "../components/CreateOrUpdateProject";
import useSession from "../hooks/useSession";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import Roles from "../helpers/roles";
import ConfirmAction from "../components/ConfirmAction";



const Projects = () => {

    const [data, setData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { session: ctx, } = useSession()
    const [actionError, setActionError] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(null);
    const selectedRowIds = useMemo(() => { return [] }, [])

    const currentUserRoles = new Roles(ctx.auth.roles)
    const isSysAdmin = currentUserRoles.includes(Roles.sysAdmin)

    const handleActionError = (errMsg) => {
        setActionError({ msg: errMsg, at: Date.now() })
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            let url = ""
            if (isSysAdmin)
                url = "/projects?names=1"
            else
                url = `/projects/?org=${ctx.auth.orgId}&names=1`
            try {
                const result = await getApiAxios().get(url)
                setData(result.data)
            } catch (error) {
                handleActionError("Failed to load data from server. " + error.message)
            }
            setIsLoading(false)
        }
        fetchData();
    }, [refreshData, ctx.auth.orgId, isSysAdmin])


    //const forceUpdate = React.useCallback(() => updateState({}), []);

    const refresh = () => {
        setRefreshData(!refreshData)
    }

    const handleAddButton = () => {
        setShowEditForm("add")
    }

    const onEditDialogExit = (data) => {
        setShowEditForm(null)
        if (data) {
            refresh()
        }
    }

    const onDeleteConfirmExit = async (isConfirmed) => {
        setShowDeleteConfirm(false)
        if (isConfirmed) {
            const ids = selectedRowIds.current
            try {
                await getApiAxios().post("projects/actions", { actions: [{ action: "delete", ids: ids }] })
                handleActionError(null)
                refresh()
            }
            catch (error) {
                handleActionError("Error while deleting: " + await getAxiosErrorDisplay(error))
            }
        }
    }


    const columns = useMemo(() => {
        const c = [
            { Header: "Name", accessor: "name", },
            { Header: "Description", accessor: "description", },
            { Header: "Storage", accessor: (row) => row.dataStorageType === 1 ? "Server" : "Local", disableGlobalFilter: true },
            { Header: "Created", accessor: (row) => tableFormatDate(row.createdAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_createdByName", },
            { Header: "Updated", accessor: (row) => tableFormatDateTime(row.updatedAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_updatedByName", },
            //{ Header: "id", accessor: "_id", disableGlobalFilter: true },
        ]
        if (isSysAdmin)
            c.splice(0, 0, { Header: "Organization", accessor: "_orgName" })
        return c
    },
        [isSysAdmin]
    );

    const tableOptions = {
        uniqueRowIdName: "_id",
        sortBy: "name",
        onRowClicked: (rowId) => {
            const project = data.find(p => p._id === rowId)
            navigate("/project", { state: project })
        },

        onDeleteRequest: async (_selectedRowIds) => {
            selectedRowIds.current = _selectedRowIds
            setShowDeleteConfirm(true)
        },

        searchContainerId: "tableFilterContainer",
        deleteIconContainerId: "deleteIconContainer",
        isLoading: data === null
    }

    return (
        <ErrorBoundary>
            <>
                <PageHeader title="Projects" onCreateClicked={handleAddButton}
                    lastActionError={actionError}
                    addLeftSlot={
                        <>
                            {!isSysAdmin && <div>{ctx.auth.orgName}</div>}
                            <div id="deleteIconContainer" />
                        </>
                    }
                    addRightSlot={
                        <>
                            <div id="tableFilterContainer" />
                        </>
                    }
                    onRefreshClicked={refresh}
                />


                <StandardTable columns={columns} initData={data || []} options={tableOptions} />
                {showEditForm !== null &&
                    <CreateOrUpdateProject show={showEditForm !== null} isUpdate={showEditForm !== "add"}
                        initData={showEditForm === "add" ?
                            { status: 1, orgId: ctx.auth.orgId }
                            : showEditForm}
                        onExit={onEditDialogExit}
                    />
                }

                <ConfirmAction message="You requested to delete one or more projects." okButtonText="Delete"
                    show={showDeleteConfirm} onConfirm={onDeleteConfirmExit} />
                {isLoading && <Spinner text="" />}
            </>
        </ErrorBoundary>
    )
}

export default Projects;