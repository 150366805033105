import PropTypes from 'prop-types'
import { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { getApiAxios, getAxiosErrorDisplay } from '../helpers/misc';

const CreateOrUpdateOrganization = ({ isUpdate, initData, show, onExit, ...props }) => {

    const [errorMsg, setErrorMsg] = useState("")
    const [showValidation, setShowValidation] = useState(false);

    const data = useRef({ ...initData });

    const dataChanged = (name, value) => {
        //const d = data;
        //d[name] = value
        //setData(d)
        data.current[name] = value
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setShowValidation(true)
            return;
        }
        else
            setShowValidation(false)

        try {
            if (!isUpdate)
                await getApiAxios().post('/organizations', data.current)
            else
                await getApiAxios().put('/organizations/' + initData._id, data.current)

            setErrorMsg("")
            onExit(data.current)
        }
        catch (error) {
            event.preventDefault();
            event.stopPropagation();

            console.error("editproj api", error);
            setErrorMsg(await getAxiosErrorDisplay(error))
        }
    }

    const handleCancel = () => {
        setShowValidation(false);
        setErrorMsg("")
        onExit(null)
    }

    return (
        <Modal show={show} centered  >
            <Form id="theForm" noValidate validated={showValidation} onSubmit={handleSubmit}>
                <Modal.Header >
                    <Modal.Title>{isUpdate ? "Update organization" : "Create new organization"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control required type="text" placeholder="Organization name" defaultValue={initData.name}
                                onChange={(e) => dataChanged("name", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="description">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control placeholder="Description" defaultValue={initData.description}
                                onChange={(e) => dataChanged("description", e.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Select defaultValue={initData.status} onChange={(e) => dataChanged("status", e.target.value)} >
                                <option value={1}>Active</option>
                                <option value={2}>Disabled</option>
                                <option value={4}>Archived</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="license">
                            <Form.Label>License</Form.Label>
                            <Form.Select defaultValue={initData.license} onChange={(e) => dataChanged("license", e.target.value)} >
                                <option value={"demo"}>Demo</option>
                                <option value={"basic"}>Basic</option>
                                <option value={"pro"}>Professional</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <row>
                        <Form.Group as={Col} md="12"  >
                            <Form.Label className="text-muted small">{initData._id && "ID: " + initData._id}</Form.Label>
                        </Form.Group>
                    </row>

                </Modal.Body>

                <Modal.Footer>
                {
                        errorMsg &&
                        <div className = 'me-auto'>
                            <span className='text-danger fw-bold'>Operation failed: </span>
                            <span className='text-danger'> {errorMsg}</span>
                        </div>
                    }
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" type="submit"  >Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>

    )
}

export default CreateOrUpdateOrganization

CreateOrUpdateOrganization.propTypes = {
    show: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onExit: PropTypes.func.isRequired,
};


