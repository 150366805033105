import React, { useEffect, useState, useMemo } from "react";
import { StandardTable } from "../components/StandardTable";
import { getApiAxios, getAxiosErrorDisplay, tableFormatDate, tableFormatDateTime, statusToString } from '../helpers/misc.js';
import Roles from "../helpers/roles";
import ErrorBoundary from "../components/ErrorBoundary"
import CreateOrUpdateUser from "../components/CreateOrUpdateUser";
import useSession from "../hooks/useSession";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import ConfirmAction from "../components/ConfirmAction";
//import { IconEmail } from "../components/Icons";


const Users = () => {

    //const navigate = useNavigate()
    const { session: ctx, } = useSession()
    const [data, setData] = useState(null);
    const [orgData, setOrgData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [actionError, setActionError] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(null);
    const selectedRowIds = useMemo(() => { return [] }, [])


    const currentUserRoles = new Roles(ctx.auth.roles)
    const isSysAdmin = currentUserRoles.includes(Roles.sysAdmin)
    //const isOrgAdmin = currentUserRoles.includes(Roles.orgAdmin)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            let url = ""
            if (isSysAdmin)
                url = "/users?names=1"
            else
                url = `/users/?org=${ctx.auth.orgId}&names=1`
            try {
                const result = await getApiAxios().get(url)
                setData(result.data)
                setActionError(null)
            }
            catch (error) {
                // setLoadError("Failed to load data from server. " + error.message)
                setActionError("Failed to load data from server. " + error.message)
            }
            setIsLoading(false)
        }
        fetchData();
    }, [refreshData, ctx.auth.orgId, isSysAdmin])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setActionError(null)
                let result
                if (isSysAdmin) {
                    result = await getApiAxios().get("/organizations")
                    setOrgData(result.data)
                }
                else {
                    result = await getApiAxios().get("/organizations/" + ctx.auth.orgId)
                    setOrgData([result.data]) // need as array
                }
            } catch (error) {
                setActionError("Failed to load data from server. " + error.message)
                console.error(error);
            }
        }
        fetchData();
    }, [refreshData, ctx.auth.orgId, isSysAdmin])

    if (data && orgData.length) {
        data.forEach(e => {
            e._orgName = orgData.find(o => e.orgId === o._id)?.name || e.orgId
        });
    }

    const refresh = () => {
        setRefreshData(!refreshData)
    }

    const handleAddButton = () => {
        setShowEditForm("add")
    }

    const onEditDialogExit = (data) => {
        setShowEditForm(null)
        if (data) {
            refresh()
        }
    }

    const onDeleteConfirmExit = async (isConfirmed) => {
        setShowDeleteConfirm(false)
        if (isConfirmed) {

            const ids = selectedRowIds.current
            if (ids.find(id => id === ctx.auth.userId)) {
                setActionError("Error while deleting: you cannot delete yourself")
                return
            }
            try {
                await getApiAxios().post("users/actions", { actions: [{ action: "delete", ids: ids }] })
                setActionError(null)
                refresh()
            }
            catch (error) {
                setActionError("Error while deleting: " + await getAxiosErrorDisplay(error))
            }
        }
    }

    const rolesToString = (roles) => {
        const r = new Roles(roles)
        return r.toString()
    }

    const columns = useMemo(() => {
        const c = [
            { Header: "Name", accessor: "name", className: "YYYYYYYYYY" },
            { Header: "Comments", accessor: "description", },
            { Header: "Status", accessor: (row) => statusToString(row.status), disableGlobalFilter: true },
            { Header: "Roles", accessor: (row) => rolesToString(row.roles), disableGlobalFilter: true },
            { Header: "Last signed in", accessor: (row) => tableFormatDateTime(row.lastLogin), disableGlobalFilter: true },
            { Header: "email", accessor: "email", },
            { Header: "mobile", accessor: "mobile", },
            { Header: "Created", accessor: (row) => tableFormatDate(row.createdAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_createdByName", },
            { Header: "Updated", accessor: (row) => tableFormatDateTime(row.updatedAt), disableGlobalFilter: true },
            { Header: "By", accessor: "_updatedByName", },
        ]
        if (isSysAdmin)
            c.splice(0, 0, { Header: "Organization", accessor: "_orgName" })
        return c
    },
        [isSysAdmin]
    );


    const tableOptions = {
        uniqueRowIdName: "_id",
        sortBy: "name",
        onRowClicked: (rowId) => {
            setShowEditForm(data.find(d => d._id === rowId))
        },

        onDeleteRequest: async (_selectedRowIds) => {
            selectedRowIds.current = _selectedRowIds
            setShowDeleteConfirm(true)
        },
        searchContainerId: "tableFilterContainer",
        deleteIconContainerId: "deleteIconContainer",
        isLoading: data === null
    }
                               //onClick={() => { handleDeleteClicked() }} />
 //                                show={isAnyRowSelected()}

    return (
        <ErrorBoundary>
            <>
                <PageHeader title="Users" onCreateClicked={handleAddButton}
                    lastActionError={actionError}
                    addLeftSlot={
                        <>
                            {!isSysAdmin && <div>{ctx.auth.orgName}</div>}
                            <div id="deleteIconContainer" />
                            {/*<IconEmail clickable={true} tooltip="Reinvite selected users"  onClick={() => { alert("clicked") }} /> */} 
                        </>
                    }
                    addRightSlot={
                        <>
                            <div id="tableFilterContainer" />
                        </>
                    }
                    onRefreshClicked={refresh}
                />

                <StandardTable columns={columns} initData={data || []} options={tableOptions} />
                {showEditForm !== null &&
                    <CreateOrUpdateUser show={showEditForm !== null} isUpdate={showEditForm !== "add"}
                        initData={showEditForm === "add" ?
                            { status: 1, orgId: ctx.auth.orgId, roles: new Roles([Roles.user]).dbValue }
                            : showEditForm}
                        onExit={onEditDialogExit}
                        orgData={orgData}
                    />
                }
                <ConfirmAction message="You requested to delete one or more users." okButtonText="Delete"
                    show={showDeleteConfirm} onConfirm={onDeleteConfirmExit} />
                {isLoading && <Spinner text="" />}
            </>
        </ErrorBoundary>
    )
}

export default Users;