//import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
//import { Tooltip } from 'react-bootstrap'
import { PencilSquare, Trash3, Search, ZoomIn, Eye, EyeSlash, Key, ArrowClockwise,At} from 'react-bootstrap-icons'


const ImageContainer = ({ show, clickable, tooltip, disabled, size, className, children, ...props }) => {
    const styles = {}
    let newClass = "stdIcon"

    const _hidden = show !== undefined && !show
    //const _disabled = disabled === undefined || !disabled

    if (_hidden) {
        styles["display"] = "none"
    }

    if (clickable) {
        newClass += " stdIconClickable"
        styles["cursor"] = "pointer"
    }

    if (className)
        newClass += " " + className
    const tt = tooltip || ""

    return (
        <>
            <span className={newClass} style={styles} title={tt} {...props}>
                {children}
            </span >
        </>
    )
}

export const IconTrash = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <Trash3 /> </ImageContainer>
    )
}
export const IconEyeOpen = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <Eye /> </ImageContainer>
    )
}
export const IconEyeClosed = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <EyeSlash size={size}/> </ImageContainer>
    )
}
export const IconEdit = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <PencilSquare size={size}/> </ImageContainer>
    )
}
export const IconSearch = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <Search size={size} /> </ImageContainer>
    )
}
export const IconZoomIn = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <ZoomIn size={size} /> </ImageContainer>
    )
}
export const IconCredentials = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <Key size={size} /> </ImageContainer>
    )
}
export const IconRefresh = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <ArrowClockwise size={size} /> </ImageContainer>
    )
}
export const IconEmail = ({ size, ...props }) => {
    return (
        <ImageContainer {...props}>  <At size={size} /> </ImageContainer>
    )
}



