import { useContext } from "react";
import SessionContext from "../context/SessionContext";

const useSession = () => {

    const sc = useContext(SessionContext)
    
    if(sc.session === undefined){ // if it has its default value which was set to null
        throw new Error("useSession has been called outside the SessionContext provider scope")
    }
    return sc
}

export default useSession;