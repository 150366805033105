import { Button, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import { IconRefresh } from './Icons';
//import { Outlet } from 'react-router-dom';



function PageHeader({ title, action, name,
    onCreateClicked, onRefreshClicked, addLeftSlot, addRightSlot, loadError, lastActionError, ...props }) {

    const [hideActionError, setHideActionError] = useState(false);

    useEffect(() => {
        setHideActionError(false)
    }, [lastActionError])

    return (
        <>
            <Helmet>
                {title !== "Home" && <title>EmpRoute | {title}</title>}
                {/* SOE: since this app does not show product/blob... pages, it is best to consolidate any ranking under the home page (origin+'/')*/}
                {/* make excemtions for new pages like product overview etc that are added in the future */}
                {window.location.pathname === "/aboutThisProductExample" ?
                    <link rel="canonical" href={window.location.href} /> :
                    <link rel="canonical" href={window.location.origin + '/'} />}
            </Helmet>

            <div className="mb-1 d-flex align-items-center">
                <div id="pheaderLeft" className="flex-grow-1 d-flex align-items-center ">
                    <div className="fs-3 ">
                        {title} <small>{action}</small>
                        {name && <span className='ms-2 fs-4 text-primary'>{name}</span>}
                    </div>
                    <div>{onCreateClicked && <Button size='sm' className="ms-2" onClick={onCreateClicked}>Create</Button>}</div>
                    <div>{onRefreshClicked && <IconRefresh clickable onClick={onRefreshClicked} className="ms-2" tooltip="Refresh" />}</div>
                    <div id="leftSlot" className='ms-2 d-flex align-items-center'>{addLeftSlot}</div>
                </div>
                <div id="pheaderRight" className='d-flex align-items-center me-2'>
                    <div id="rightslot" className='ms-2 d-flex align-items-center'>{addRightSlot}</div>
                </div>
            </div>


            <div className="mt-2 mb-1" >
                {loadError && <Alert variant="danger" style={{ display: "inline" }}>{loadError}</Alert>}

                {lastActionError && !hideActionError &&
                    <Alert variant="danger" dismissible style={{ display: "inline" }}
                        onClose={() => setHideActionError(true)}>
                        {lastActionError}
                    </Alert>
                }
            </div>
        </>
    )
}

// from globalcre

PageHeader.propTypes = {
    //   setToken: PropTypes.func.isRequired
};





export default PageHeader                