// from https://github.com/gitdagray/react_protected_routes/blob/main/src/context/AuthProvider.js

import { useLocation, Navigate, Outlet } from "react-router-dom";
import useSession from "../hooks/useSession";
import Roles from "../helpers/roles"
//import * as Sentry from "@sentry/browser";

const RequireAuth = ({ allowedRoles }) => {
    const { session } = useSession();
    const location = useLocation();

    if (session.auth && session.auth.roles) {
        const roles = new Roles(session.auth.roles)
        if (roles.includesAny(allowedRoles)) {
            return (
                <Outlet />
            )
        }
        else{
            //Sentry.captureMessage(`Unauthorized page:  ${location.pathname} user: ${session.auth.userName} ${session.auth.userId}`);
            return (
                <h2>You are not authorized to view this page</h2>
            )
        }
    }
    // should try to use the refresh token here and navigate to login page only if this fails!
    return (
        <Navigate to="/login" state={{ from: location }} replace />
    )
    /*
        return (
            session?.roles?.find(role => allowedRoles?.includes(role))
                ? <Outlet />
                : session?.user
                    ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/login" state={{ from: location }} replace />
        );
    */
}

export default RequireAuth;