import LoginComponent from '../components/LoginComponent';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function Login({ stayOnPage }) {

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <LoginComponent></LoginComponent>
        </GoogleOAuthProvider>
    )
}

LoginComponent.propTypes = {
    //   setToken: PropTypes.func.isRequired
};