//import PropTypes from 'prop-types'

import { Modal, Button } from "react-bootstrap";

const ConfirmAction = ({show, message, okButtonText = "OK", onConfirm, onCancel, actionDef: actionParms}) => {

    return (

        <Modal show={show} size="sm" centered >
            <Modal.Header>Please confirm</Modal.Header>
            <Modal.Body style={{paddingBottom: 0}}>
                <p>{message}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" size='sm' onClick={()=>onConfirm(false, actionParms)}>Cancel</Button>
                <Button variant="primary" size='sm' onClick={()=>onConfirm(true, actionParms)}>{okButtonText}</Button>
            </Modal.Footer>
        </Modal>
    )
}


export default ConfirmAction

//ConfirmAction.propTypes = { when: PropTypes.bool.isRequired };