import React from 'react';
import { Button } from 'react-bootstrap';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
        this.prevPath = null
    }

    static getDerivedStateFromError(error) {
        // return causes hasError in state to be set to true so the next render will show the fallback UI.
        //console.log("getDerivedStateFromError", error)
        return { hasError: true, error: error };
    }

    componentDidCatch(error, componentInfo) {
        // You can also log the error to an error reporting service
        console.log("componentDidCatch", /*error,*/ componentInfo);
    }
    // by design the error boundary remains in error state, once an error is caught.
    // this would let every other following route to aany other url fail with same "something went wrong" display
    // the following 2 functions avoid this by resetting the error state when another route is selected
    componentDidMount() {
        this.prevPath = window.location.pathname;
    }
    componentDidUpdate(prevProps) {
        if (window.location.pathname !== this.prevPath)
            this.setState({ hasError: false, error: null });
        this.prevPath = window.location.pathname;
    }

    retry = () => {
        this.setState({ hasError: false, errorInfo: null });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <h3>Oops, something went wrong.</h3>
                    <Button onClick={this.retry}>Retry</Button>
                    <br /><br />
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error.toString()}
                        {this.state.error.stack}
                        <br />
                        {/*this.state.errorInfo.componentStack*/}
                    </details>

                </>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary