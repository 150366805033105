// contrary to native js broadcast-channel (with support only in recent browsers), this works
// with older ones by automatically falling back to other methods like 'localstorage'

// NOTE: if you listen to messages in a tab you will NOT receive messages sent from this tab, only other tabs will receive them
// (this is not an implementation in broadcast-channel, but this is how the native JS broadcast channel works)

// NOTE: It seems that sending exactly the same msg multiple times, only he first will be received in other tabs
// the code below changes this by adding also a 'sentAt' property

import { BroadcastChannel } from 'broadcast-channel'
import { v4 as uuid } from 'uuid'

function BroadcastSession() { }
BroadcastSession.tabId = uuid() // set as static for this browser tab


const channelOptions = {
    //type: 'localstorage', // (optional) enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
    webWorkerSupport: false, // (optional) set this to false if you know that your channel will never be used in a WebWorker (increases performance)
};

const channel = new BroadcastChannel('ourBroadcast', channelOptions)

export const addBroadcastListener = (handler) => {
    channel.addEventListener('message', handler)
}
export const broadcastAction = (action) => {
    console.log("sending broadcast", new Date().toTimeString(), action)
    channel.postMessage({ tabId: BroadcastSession.tabId, sentAt: new Date(), action: action })
}

// not really needed. This was added because I thought that the broadcast mechanism would also cause the msg sent from a tab
// to be recieved in this tab, too. This turned out to be not true
export const broadCastIsFromOtherTab = (msg) => {
    return BroadcastSession.tabId !== msg.tabId
}




