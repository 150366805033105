import PropTypes from 'prop-types'
import { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Roles from '../helpers/roles';
import Select from "react-select"
import { getApiAxios, getAxiosErrorDisplay } from '../helpers/misc';
import useSession from '../hooks/useSession';


const CreateOrUpdateUser = ({ isUpdate, initData, orgData, show, onExit, ...props }) => {

    const [errorMsg, setErrorMsg] = useState("")
    const [showValidation, setShowValidation] = useState(false);
    const data = useRef({ ...initData });
    const sendInviteEmail = useRef(isUpdate ? false : true)
    const { session: ctx, } = useSession()

    const dataChanged = (name, value) => {
        data.current[name] = value
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setShowValidation(true)
            return;
        }
        else
            setShowValidation(false)

        try {
            if (!isUpdate)
                await getApiAxios().post('/users' + (sendInviteEmail.current ? "?sm=1" : ""), data.current)
            else
                await getApiAxios().put('/users/' + initData._id + (sendInviteEmail.current ? "?sm=1" : ""), data.current)

            setErrorMsg("")
            onExit(data.current)
        }
        catch (error) {
            event.preventDefault();
            event.stopPropagation();

            console.error("editproj api", error);
            setErrorMsg(await getAxiosErrorDisplay(error))
        }
    }

    const handleCancel = () => {
        setShowValidation(false);
        setErrorMsg("")
        onExit(null)
    }

    const getAllOrgOptions = () => {
        const options = []
        orgData.forEach(rr => { options.push({ value: rr._id, label: rr.name }) })
        return options
    }
    const orgOptions = getAllOrgOptions()

    const getDefaultOrgOptions = (defRoles) => {
        const options = []
        const org = orgOptions.find(o => o.value === initData.orgId)
        if (org) options.push(org)
        return options
    }


    const getAllRoleOptions = () => {
        //const r = new Roles(0xffffffff)
        const r = new Roles(ctx.auth.roles).getRoleAssignmentRightsAsRole()
        const allRoles = r.toObjectArray()
        const allRoles2 = []
        allRoles.forEach(rr => { allRoles2.push({ value: rr.const, label: rr.name }) })

        return allRoles2
    }

    const roleOptions = getAllRoleOptions()

    const getDefaultRoleOptions = () => {
        const defOptions = []
        roleOptions.forEach(o => {
            if (o.value & initData.roles)
                defOptions.push(o)
        })
        return defOptions
    }

    const yyyinputchg = (a, b, c, d, e, f) => { console.log(a) }

    const handleOnMultiSelectChange = function (fieldName, isFlagField) {
        return function (selectedArray, action) {

            if (isFlagField) {
                let flags = 0
                selectedArray.forEach(r => { flags |= r.value })
                dataChanged(fieldName, flags)
            }
            else
                // data property holds an array of values
                dataChanged(fieldName, selectedArray)
        }
    }

    /*
filterOption
getOptionValue???
hideSelectedOptions
isRtl
menuShouldScrollIntoView
styles/theme

    border: solid;
    border-width: thin;
    border-color: #9b9bcd;
    border-radius: 10px;


    onChange={handleOnMultiSelectChange("roles", true)} 
    onInputChange={yyyinputchg}
    onBlur={yyyiBlur}
    */

    return (
        <Modal show={show} centered size="xl"  >
            <Form id="theForm" noValidate validated={showValidation} onSubmit={handleSubmit}>
                <Modal.Header >
                    <Modal.Title>{isUpdate ? "Update user" : "Create new user"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="4" controlId="organization">
                            <Form.Label>Organization</Form.Label>
                            <Select required
                                options={orgOptions}
                                defaultValue={getDefaultOrgOptions()}
                                onChange={(selected) => dataChanged("orgId", selected.value)}
                                get_Option_Label={option => option.name}
                                get_Option_Value={option => option._id}
                            />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="8" className='d-flex justify-content-end' >
                            <Form.Text className="text-muted">{initData._id && "ID: " + initData._id}</Form.Text>
                        </Form.Group>

                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Name</Form.Label>
                            <Form.Control size="sm" required type="text" defaultValue={initData.name}
                                onChange={(e) => dataChanged("name", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="8" >
                            <Form.Label >Comments</Form.Label>
                            <Form.Control size="sm" placeholder="Description" defaultValue={initData.description}
                                onChange={(e) => dataChanged("description", e.target.value)} />
                        </Form.Group>

                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" >
                            <Form.Label>Status</Form.Label>
                            <Form.Select size="sm" defaultValue={initData.status} onChange={(e) => dataChanged("status", e.target.value)} >
                                <option value={1}>Active</option>
                                <option value={2}>Disabled</option>
                                <option value={4}>Archived</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="8" controlId="roles">
                            <Form.Label>Roles</Form.Label>
                            <Select isMulti required
                                inputId="roles"

                                options={roleOptions}
                                defaultValue={getDefaultRoleOptions()}
                                onChange={handleOnMultiSelectChange("roles", true)}
                                onInputChange={yyyinputchg}
                                name="SomeName"
                                isInvalid={true}

                                style={{ borderColor: this?.state.validity.valid ? "red" : "blue" }}
                            />

                            <div className="invalid-feedback">Required whatever just testing</div>
                            <Form.Control.Feedback type="invalid">3333Required</Form.Control.Feedback>

                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control size="sm" required type="email" defaultValue={initData.email}
                                onChange={(e) => dataChanged("email", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required valid email address</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Mobile phone</Form.Label>
                            <Form.Control size="sm" type="tel" defaultValue={initData.mobile}
                                onChange={(e) => dataChanged("mobile", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Invalid phone number  </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" >
                            <Form.Label>Password</Form.Label>
                            <Form.Control size="sm" required={!isUpdate} type="text" defaultValue={initData.password}
                                placeholder={isUpdate ? "Change users current password" : undefined}
                                onChange={(e) => dataChanged("password", e.target.value)}

                            />
                            <Form.Text id="passwordHelpBlock" muted>
                                Your password must be 8-20 characters long, contain letters and numbers,
                                and must not contain spaces,....
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="">
                        <Form.Group >
                            <Form.Check
                                label={isUpdate ? "Resend invitation email" : "Send invitation email"}
                                type="checkbox"
                                defaultChecked={sendInviteEmail.current}
                                onChange={(e) => sendInviteEmail.current = e.target.checked} />
                        </Form.Group>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    {
                        errorMsg &&
                        <div className = 'me-auto'>
                            <span className='text-danger fw-bold'>Operation failed: </span>
                            <span className='text-danger'> {errorMsg}</span>
                        </div>
                    }
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" type="submit"  >Save</Button>
                </Modal.Footer>
            </Form>
        </Modal >

    )
}

export default CreateOrUpdateUser

CreateOrUpdateUser.propTypes = {
    show: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onExit: PropTypes.func.isRequired,
};

/*
function getDefaultValue() {
    if (!defaultValue) return null;

    if (!multiple) {
      return options.find(option => option.id === defaultValue);
    }

    return options.filter(option => defaultValue.includes(option.id));
  }
  return (
    <Select
      name={fieldName}
      aria-label={fieldName}
      options={options}
      isMulti={multiple}
      defaultValue={getDefaultValue()}
      ref={refSelect}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      className="enhanced-select"
      menuPlacement="auto"
      {...rest}
    />
  );
  */