import axios from "axios"
import { /*getStoredToken,*/ getStoredTokenIfNotExpired } from "./auth"
import * as Sentry from "@sentry/react";

export const getApiAxios = () => {

    const apiAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        //timeout: 1000, 
        //headers: { Authorization: `Bearer ${getStoredToken()}` },
        withCredentials: false, // default
        // timeout: 1000,
    });

     apiAxios.interceptors.request.use(function (config) {
        const token = getStoredTokenIfNotExpired()
        if (token)
            config.headers.Authorization = `Bearer ${token}`
        return config;
    });

    // Response interceptor for API calls. 
    // On errors, log/sentry and make a msg displayed to user
    apiAxios.interceptors.response.use(
        (res) => {
            //debugger
            return res;
        },
        async (err) => {
            //debugger
            let logMsg
            let logIt = true

            if (!err.response) {
                logMsg = `Could not connect to server ${err.config.method} ${err.config.url} (${err.code})`
            }
            else {
                //const errDescerror.response.data.errDesc
                let returnedDataStr;
                try {
                    returnedDataStr = JSON.stringify(err.response.data)
                }
                catch { }
                logMsg = `API call failed: ${err.response.status} ${err.config.method} ${err.config.url} ${returnedDataStr}`
                if ([].includes(err.response.status))
                    logIt = false

            }

            console.error(logMsg)
            if (logIt) {
                logIt = true
                Sentry.captureMessage(logMsg)
                Sentry.captureException(err)
                logIt = false
            }
            err._dispMsg = await getAxiosErrorDisplay(err)
            console.error("axios error:", err._dispMsg)


            return Promise.reject(err);
        }
    )


    //console.log("process.env.REACT_APP_API_URL",process.env.REACT_APP_API_URL)

    /*
    The following works. But we changed the approach to proactively renew tokens after half their life span is used
    instead to wait until it expires. 
    Left here for reference only


    // check if we have a not expired token and try to refresh token if not.
    // without doing so means that we always would wait for a response 401 before doing refresh
    // which would be painful if doing a large post, and then refresh-token and then post again

    apiAxios.interceptors.request.use(
        async (config) => {
            //debugger
            if (!config._retry) {
                config._retry = true
                const token = getStoredTokenIfNotExpired();
                if (!token)
                    // using the same instance of apiAxios?? would that work?
                    // what to do if refresh fails?
                    await refreshToken(apiAxios) 
                return config;
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Response interceptor for API calls. 
    // if api rerturns 401, try to refresh token and try the call again
    // since we have interceptors.request.use in effect, 401 should only occur in the rare
    // case that a refresh token exired during the call, for instance in a lengthy/big post
    apiAxios.interceptors.response.use(
        (res) => {
            //debugger
            return res;
        },
        async (err) => {
            //debugger
            const originalConfig = err.config;

            if (err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        //debugger
                        return apiAxios(originalConfig);
                    } catch (_error) {
                        //debugger
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }

                        return Promise.reject(_error);
                    }
                }

                if (err.response.status === 403 && err.response.data) {
                    return Promise.reject(err.response.data);
                }
            }

            return Promise.reject(err);
        }
    )

    const refreshToken = async (axiosInstance) => {
         try {
            const rs = await axios.post(process.env.REACT_APP_API_URL + "/refreshtoken", {
                refreshToken: getStoredRefreshToken(),
            });

            const { token: newToken, refreshToken: newRefreshToken } = rs.data;
            setStoredToken(newToken)
            setStoredRefreshToken(newRefreshToken)
            // set for following request with updated token
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        }
        catch (error) {
            throw new Error("getApiAxios: could not get a refresh token ")//, { cause: error })
        }
    }

    */



    return apiAxios
}

export const getAxiosErrorDisplay = async (error) => {

    let errorMsg;

    if (!error.response) {
        errorMsg = `Could not connect to server. Please try later. (${error.code})`
    }
    else {
        if (error.response.status === 400) {

            errorMsg = error.response.data.errDesc

            if (errorMsg.startsWith('E11000 ')) {
                // E11000 duplicate key error collection: test.projects index: name_1 dup key: { name: \"ptoggg\" }
                // for compund unique keys"
                // E11000 duplicate key error collection: test.projects index: name_1_organizationId_1 dup key: { name: "Test 3", orgId: "00000000-0000-0000-000000000000" }'

                // the following will result in a bit strange error text if a prop value includes ", " 
                const start = errorMsg.indexOf('{')
                const end = errorMsg.indexOf('}')
                try {
                    const elems = errorMsg.substring(start + 1, end).split(", ")
                    let fields = ""
                    elems.forEach(e => {
                        let propKeyName = e.split(": ")[0].trim()
                        if (propKeyName.endsWith("Id")) // "orgId" etc
                            propKeyName = propKeyName.slice(0, -2)
                        fields += propKeyName + " + "
                    });
                    fields = fields.slice(0, -3)
                    errorMsg = `${fields} must be unique`
                }
                catch (e) { }
            }
        }
        else if (error.response.status === 403)
            errorMsg = "Unauthorized request. " + error.response.data.errDesc
        else if (error.response.status > 400 && error.response.status < 500) {
            if (!error.config.responseType || error.config.responseType === "json")
                errorMsg = error.response.data.errDesc
            else {
                const text = await error.response.data.text()
                errorMsg = JSON.parse(text).errDesc
            }
        }
        else if (error.response.status === 500)
            errorMsg = "Server error. Please try later."
        else
            errorMsg = "Unknown error. Please try later."
    }
    return errorMsg
}

export const tableFormatDateTime = (isoDateStr) => {
    if (!isoDateStr)
        return ""
    var d = new Date(Date.parse(isoDateStr));
    //return d.toLocaleDateString() + " " + d.toLocaleTimeString()
    return d.toLocaleDateString() + " " + d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export const tableFormatDate = (isoDateStr) => {
    if (!isoDateStr)
        return ""
    var d = new Date(Date.parse(isoDateStr));
    return d.toLocaleDateString()
}

export const statusToString = (status) => {
    if (status === 1)
        return "Active"
    if (status === 2)
        return "Disabled"
    if (status === 4)
        return "Archived"
    return "Unknown"
}

export const asyncDelay = async (delayMs) => {
    await new Promise(resolve => setTimeout(resolve, delayMs))
}
