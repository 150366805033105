import { Helmet } from "react-helmet"
import SupportTicket from "../components/SupportTicket."
import { useState } from "react";
import { Button, Toast } from "react-bootstrap";

const Home = () => {

  const [showTicketForm, setShowTicketForm] = useState(false);
  const [ticketActionSuccess, setTicketActionSuccess] = useState(false);

  const onSupportTicketExit = (e) => {
    setShowTicketForm(false);
    if (e)
      setTicketActionSuccess("Ticket created successfully")
  }
  
  return (
    <>
      <Helmet>
        <title>How EmpRoute can help you to evaluate the impact of office relocation on employees' commute time and distance</title>
        <link rel="canonical" href={window.location.href} /> :
      </Helmet>

      <div className="container">
        <div className="row content mt-4 shadow rounded">
          <div className="col-lg-8">
            <h1 className="mt-2 mb-3">The EmpRoute Solution: Analyzing the Impact of Office Relocations on Employee Commuting</h1>
            <h3>Why is it Important</h3>
            <p>Choosing a new office location can be challenging in many ways. One of the most critical issues is the impact on employee commutes.</p>
            <p>So, you have found one or more potential locations in a nearby city with lower rents, but the average commute time
              for your 500 employees would increase by 50%. How many will try to find a job at another, more conveniently located company?
              In certain countries, some employees may also be entitled to financial compensation.</p>
            <h3>The EmpRoute Solution Can Help with a Few Simple Steps</h3>
            <ul>
              <li className="mb-1">Enter the names and addresses of all employees into a spreadsheet. Data can be copied from an Excel file that you probably already have. Autocomplete will present valid options as you type, similar to Google & Bing Maps.</li>
              <li className="mb-1">Enter the addresses of the current office and also the potential new ones.</li>
              <li className="mb-1">Specify the desired departure times. For example: 7:00, 9:00, 15:00, 17:00, next Tuesday. Typical traffic conditions at this time will be used where supported.</li>
              <li className="mb-1">Validate the addresses. EmpRoute uses geolocation services to validate each address.
                <ul>
                  <li>Is a valid street address.</li>
                  <li>Can be resolved unambiguously, meaning that only one real street exists that matches the address.</li>
                </ul>
                For each valid address, its geographical location is determined (latitude and longitude coordinates).
              </li>
              <li className="mb-1">Create reports. These include
                <ul>
                  <li>Raw data showing the duration and distance of each employee to/from each office location and for each specified time.</li>
                  <li>Data overview for each office location.</li>
                  <li>A summary comparing the different office locations and showing the overall impact of the relocation.</li>
                </ul>
              </li>
              <li className="mb-1">For further processing, the data and reports can be exported to Excel.</li>
            </ul>

            <h3>How to Join</h3>
            <p>EmpRoute supports single users as well as collaboration among users in an organization, for example, in a commercial real estate broker firm.</p>
            <p>EmpRoute is currently invite-only. If you work in an organization that already uses EmpRoute, ask someone to invite you.
               Otherwise, please contact us. 
               <Button variant="outline-primary" size='sm' onClick={() => setShowTicketForm(true)}>Make an inquiry</Button></p>
          </div>
          <div className="col-lg-4 mt-3">
            <img className="img-fluid" src="/pexels-max-avans-5075320-small.jpg" alt="" />
          </div>
        </div>
      </div>

      {showTicketForm &&
        <SupportTicket show={true} initData={{}} onExit={e => { onSupportTicketExit(e) }} />
      }
      <div style={{ position: "fixed", top: 0, right: 0, zIndex: 10000 }}>
        <Toast bg="secondary" onClose={() => setTicketActionSuccess(null)} show={ticketActionSuccess} delay={5000} autohide>
          <Toast.Header>
            <strong className="me-auto">{ticketActionSuccess}</strong>
          </Toast.Header>
        </Toast>
      </div>

    </>
  )
}

export default Home