import PropTypes from 'prop-types'
import { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha"
import useSession from "../hooks/useSession";



//import Select from "react-select"

import { getApiAxios, getAxiosErrorDisplay } from '../helpers/misc';

const categories = [
    //{ value: null, label: "<Select category>" },
    { value: "inquire", label: "Request for information", forAuth: true, forAnonym: true },
    { value: "join", label: "Request to join", forAuth: false, forAnonym: true },
    { value: "login", label: "Login issues", forAuth: true, forAnonym: true },
    { value: "operation", label: "Operational issues", forAuth: true, forAnonym: false },
    { value: "billing", label: "Billing", forAuth: true, forAnonym: false }
]

/*
const getCategoryOptionsXXX = () => {
    const options = [
        //{ value: null, label: "<Select category>" },
        { value: "inquire", label: "Request for information" },
        { value: "join", label: "Request to join" },
        { value: "login", label: "Login issues" },
        { value: "operation", label: "Operational issues" },
        { value: "billing", label: "Billing" }
    ]

    //options.forEach(rr => { options.push({ value: rr._id, label: rr.name }) })
    return options
}
*/
/*
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];
*/


const getCategoryOptions = (anonymous) => {
    const map = categories.map(opt => {
        if ((anonymous && opt.forAnonym) || (!anonymous && opt.forAuth) )
            return <option value={opt.value} key={opt.value}>{opt.label}</option>
        else
            return null
    })
    return map
}

const SupportTicket = ({ mode, anonymous, initData, show, onExit, ...props }) => {

    anonymous = !useSession().session.auth

    const [errorMsg, setErrorMsg] = useState("")
    const [showValidation, setShowValidation] = useState(false)
    const captchaRef = useRef(null)
    const [missingCaptcha, setMissingCaptcha] = useState(false)



    //const data = useRef({ ...initData });
    const data = useRef({})

    const dataChanged = (name, value) => {
        data.current[name] = value
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        let reCaptchaToken = null
        let rejected = false
        if (anonymous) {
            reCaptchaToken = captchaRef.current.getValue()
            if (!reCaptchaToken) {
                rejected = true
                setMissingCaptcha(true)
            }
        }
        if (form.checkValidity() === false) {
            rejected = true
            setShowValidation(true)
        }
        if (rejected)
            return

        setShowValidation(false)
        setMissingCaptcha(false)
        if (anonymous)
            captchaRef.current.reset()

        const saveData = { ...data.current }
        if (anonymous) {
            data.current.comment =
                "Name: " + data.current.name + "\n" +
                "Organization: " + data.current.org + "\n" +
                "Email: " + data.current.email + "\n------------------------------\n\n" +
                data.current.comment

            delete data.current["name"];
            delete data.current["org"];
            delete data.current["email"];

            data.current.reCaptchaToken = reCaptchaToken
        }
        console.log("ticketData", data.current)

        try {
            await getApiAxios().post('/tickets', data.current)
            setErrorMsg("")
            onExit && onExit(true)
        }
        catch (error) {
            event.preventDefault();
            event.stopPropagation();

            data.current = saveData
            console.error("tickets api", error);
            setErrorMsg(await getAxiosErrorDisplay(error))
        }
    }

    const handleCancel = () => {
        setShowValidation(false);
        setErrorMsg("")
        setMissingCaptcha(false)
        onExit && onExit(null)
    }

    return (
        <Modal show={show} centered  >
            <Form id="theForm" noValidate validated={showValidation} onSubmit={handleSubmit}>
                <Modal.Header >
                    <Modal.Title>{"Create Ticket"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Row className="mb-3">
                        {/*
                        <Form.Group as={Col} md="8" controlId="xxxxxxxxxxxxx">
                            <Form.Label>Category</Form.Label>
                            <Select required defaultValue={"strawberry"} options={options} onChange={(e) => console.log("sel:", e)}>
                            </Select>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                        */}

                        <Form.Group as={Col} md="8" className="mb-2" controlId="category">
                            <Form.Label className='mb-1'>Category</Form.Label>
                            <Form.Select required defaultValue={""} onChange={(e) => dataChanged("category", e.target.value)}>
                                <option value={""} disabled >{"Select..."}</option>
                                {getCategoryOptions(anonymous)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-2" controlId="subject">
                            <Form.Label className='mb-1'>Subject</Form.Label>
                            <Form.Control required type="text" placeholder="Subject" defaultValue={initData.subject}
                                onChange={(e) => dataChanged("subject", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                        {anonymous &&
                            <>
                                <Form.Group as={Col} md="12" className="mb-2" controlId="name">
                                    <Form.Label className='mb-1'>Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Your name" defaultValue={initData.name}
                                        onChange={(e) => dataChanged("name", e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-2" controlId="Org">
                                    <Form.Label className='mb-1'>Organization</Form.Label>
                                    <Form.Control required type="text" placeholder="Your organization" defaultValue={initData.org}
                                        onChange={(e) => dataChanged("org", e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-2" controlId="email">
                                    <Form.Label className='mb-1'>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="Your email address" defaultValue={initData.email}
                                        onChange={(e) => dataChanged("email", e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Required valid email address</Form.Control.Feedback>
                                </Form.Group>
                            </>
                        }

                        <Form.Group as={Col} md="12" className="mb-2" controlId="comment">
                            <Form.Label className='mb-1'>Comment</Form.Label>
                            <Form.Control required as="textarea" rows="6" placeholder="Describe your issue or inquiry" defaultValue={initData.comment}
                                onChange={(e) => dataChanged("comment", e.target.value)} />
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>

                    </Row>

                    {anonymous &&
                        <ReCAPTCHA size='normal' ref={captchaRef} onChange={(token) => { setMissingCaptcha(!token) }} sitekey="6LdgmW8pAAAAAK3J66JjxHV00yu2VF4XRYD1TXiE" />
                    }
                    {missingCaptcha && <Form.Label className='text-danger' >Please verify you are not a robot </Form.Label>
                    }

                </Modal.Body>

                <Modal.Footer>
                    {
                        errorMsg &&
                        <div className='me-auto'>
                            <span className='text-danger fw-bold'>Submission failed: </span>
                            <span className='text-danger'> {errorMsg}</span>
                        </div>
                    }
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" type="submit"  >Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>

    )

}

export default SupportTicket

SupportTicket.propTypes = {
    show: PropTypes.bool.isRequired,
    //anonymous: PropTypes.bool.isRequired,
    onExit: PropTypes.func.isRequired,
};


