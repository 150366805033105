import React from 'react';
import App from './App'
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
//import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

//make it faster to figure out that that strange netlify issue re-occured, when env vars suddenly undefined
if (!process.env.REACT_APP_API_URL)
  console.error("MISSING ENVIRONMENT")
console.log("env:", process.env.NODE_ENV, "api:", process.env.REACT_APP_API_URL)

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    //integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));

/*
<React.StrictMode>
    <App />
</React.StrictMode>
*/

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
