import { useState, useEffect, useMemo, useRef, forwardRef } from "react";
import { useTable, useGlobalFilter, useAsyncDebounce, useRowSelect } from "react-table";
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup'
import { FormControl } from "react-bootstrap";
import { IconTrash, IconSearch } from "./Icons";

export function GlobalFilter({ globalFilter, setGlobalFilter, className, tableFilterId }) {
    const [value, setValue] = useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <InputGroup size="sm" id={tableFilterId} className={className}>
            <InputGroup.Text className='ps-2 pe-1' >
                <IconSearch size={"0.7rem"} style={{ marginLeft: "0px", marginRight: "2px" }} />
            </InputGroup.Text>
            <FormControl type="text" placeholder="Search records" value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            />
        </InputGroup>
    );
};

//////////////

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <input type="checkbox" ref={resolvedRef} {...rest} />
        )
    }
)

////////////////

const sortData = (data, sortByKey) => {
    if (!data || !sortByKey)
        return

    data.sort(function (a, b) {
        const nameA = a[sortByKey].toLowerCase(); // ignore casing
        const nameB = b[sortByKey].toLowerCase();
        // sort in an ascending order
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0;
    });
}


////////////////
/*
    options:
    uniqueRowIdName name of the data property to be used and returned as row id. Defaults to meaningless "0", "0.0" etc
    onRowClicked optional (rowId, currentRow)
    onDeleteRequest optional (selectedRowIdsArray) 
    deleteIconContainerId 
    sortBy Field name of initial sort of data, optional, default no sort
    searchContainerId optional. Will move the created search filter to the specified container
    isLoading if set, the column headers are shown, but with "loading" instead "No matching data found"
    onSelectionChanged>>>???, a function to be called when selection changes. the parm will be a array of selected row-ids
*/
export function StandardTable({ columns, initData, options }) {

    const tableDeleteIconId = "tableDeleteIcon"
    const tableFilterId = "tableFilter"

    useEffect(() => {
        // move the icon and filter to some other part in the DOM (e.g. page header)
        if (options?.searchContainerId) {
            const tableDeleteIcon = document.getElementById(tableDeleteIconId)
            const deleteIconContainer = document.getElementById(options.deleteIconContainerId)
            deleteIconContainer.appendChild(tableDeleteIcon)

            const filter = document.getElementById(tableFilterId)
            const filterContainer = document.getElementById(options.searchContainerId)
            filterContainer.appendChild(filter)
        }

    }, [options.searchContainerId, options.deleteIconContainerId])

    const data = useMemo(() => { sortData(initData, options?.sortBy); return initData }, [initData, options?.sortBy])

    const getRowId = (row, relativeIndex, parent) => {
        if (!options?.uniqueRowIdName)
            //use default sequential id assigment    
            return parent ? [parent.id, relativeIndex].join('.') : relativeIndex
        else {
            // use specified field inside data
            const uniqueId = row[options.uniqueRowIdName]
            return parent ? [parent.id, uniqueId].join(".") : uniqueId;
        }
    };

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps,          // table props from react-table
        getTableBodyProps,      // table body props from react-table
        headerGroups,           // headerGroups, if your table has groupings
        rows,                   // rows for the table based on the data passed
        prepareRow,             // Prepare the row (this function needs to be called for each row before getting the row props)
        setGlobalFilter,
        //selectedFlatRows,
        state: { globalFilter, selectedRowIds },
    } = useTable({
        columns,
        data,
        getRowId,
    },
        useGlobalFilter,
        useRowSelect,
        //getRowId : (row, relativeIndex, parent)=>{console.log("getRowId", row)},

        // add leading column for selection check box
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox className="selectCheckbox"  {...getToggleAllRowsSelectedProps()} />
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox className="selectCheckbox" {...row.getToggleRowSelectedProps()} />
                    ),
                    className: "indexRowActions" // for data cells, not in header
                },
                ...columns,
            ])
        }
    );

    const handleRowClicked = (row, e) => {

        if (["INPUT", "CHECKBOX", "BUTTON", "A"].includes(e.target.tagName)
            || window.getSelection().toString().length > 0 // allow copyiing text
            || e.target.classList.contains('indexRowActions')
            || e.target.parentElement.classList.contains('indexRowActions'))
            return

        options?.onRowClicked(row.id, row.values) // notify parent
    }

    const getSelectedRowKeys = () => {
        // selectedRowIds is not an array, but strange object with id:true  {"1":true,"2":true}
        const ids = []
        Object.keys(selectedRowIds).forEach(
            e => { ids.push(e) })
        return ids
    }

    const isAnyRowSelected = () => {
        return selectedRowIds && Object.keys(selectedRowIds).length
    }
    //const xxx = getSelectedRowKeys()
    return (
        <>
            <div className="mb-1 d-flex bd-highlight align-items-center">
                <div className="flex-grow-1 "></div>
                <div className="">
                    {options?.onDeleteRequest &&
                        <span id={tableDeleteIconId}>
                            <IconTrash clickable={true} tooltip="Delete selected rows"
                                show={isAnyRowSelected()}
                                onClick={() => { options?.onDeleteRequest(getSelectedRowKeys()) }} />
                        </span>
                    }
                </div>
                <div className="">
                    <GlobalFilter tableFilterId={tableFilterId} className="ms-2 me-2" globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
            </div>


            {/* Use BTable for bootstrap, 'table' otherwise. Use striped? */}
            <div>
                <Table responsive bordered hover size="sm" className="stdTable"  {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr  {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={(e) => handleRowClicked(row, e)}                                    >
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>
                                                {cell.render("Cell")}
                                            </td>;
                                        })}
                                    </tr>
                                );
                            })}
                        {rows.length === 0 &&
                            <tr>
                                <td>
                                    {options.isLoading ? "Loading data..." : "No matching data found"}
                                </td>
                            </tr>
                        }

                    </tbody>
                </Table>
            </div>
        </>
    );
}