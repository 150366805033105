import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const Spinner = ({ text }) => {
    const [showSpinner, setShowSpinner] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 1000);
        return () => clearTimeout(timer);
    });

    //return showSpinner && <Spinner size={size} color="primary" />;

    return (
        showSpinner &&
        <div className="centeredSpinnerContainer"  >
            <BootstrapSpinner variant="primary" as="span" animation="border" size={false ? "sm" : ""} role="status" >
                {text ?
                    <span>{text}...</span>
                    :
                    <span className="visually-hidden">Working...</span>
                }
            </BootstrapSpinner>
        </div >
    )
}
export default Spinner
