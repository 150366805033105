import { createContext, useState } from "react";
import { authOptions, getStoredTokenIfNotExpired, createAuthForSessionContext, refreshTokenIfNeeded } from '../helpers/auth';
import { useIdleTimer } from 'react-idle-timer'
import { logout } from '../helpers/auth'
import { clearInterval as wt_clearInterval, setInterval as wt_setInterval } from 'worker-timers'
import { addBroadcastListener } from '../helpers/broadcast';
import { /*getMsgToDisplay,*/ setMsgToDisplay } from "../helpers/globalVars";

const SessionContext = createContext(null); // null needed in useSession

export const SessionProvider = ({ children }) => {


    // set the initial context based on a not-expired token stored by a prior browser session, if found

    //note: since the initial setup of the state (below) must change state, but using 
    // setSession() would cause a loop of inifinite re-rendering
    //  - make session&setSession be a vairable (instead of std codeing with const
    //  - just change the session variable instead of using setState
    let [session, setSession] = useState({});


    const storedToken = getStoredTokenIfNotExpired()
    const auth = createAuthForSessionContext(storedToken, true)

    session = { auth: auth, userPref: { countryId: "il", langId: "en" } }
    console.log("### Initialize session again to ", auth)

    //listen to login/logout messages sent by our app running in another browser tab
    // e.g.: a logout in one tab will cause signout also in our app in all other browser tabs

    const broadcastListenHandler = (msg) => {
        console.log("broadcast recieved", new Date().toTimeString(), msg)
        if (msg.action === 'loggedout') {
            if (session.auth) {
                logout(true)
                setSession({})
                setMsgToDisplay("You have signed out in another browser tab")
            }
        }
        if (msg.action === 'loggedin') {
            setSession({})
        }
    }

    addBroadcastListener(broadcastListenHandler)
    //broadcastAction("hi everyone the app is up")

    const onIdle = () => {
        // after a first onIdle the user is logged out. If he then shows some activity without 
        // logging in and then stops activity a next onIdle can occur. In that case don't do anything
        if (session.auth) {
            console.log("onIdle logging out", new Date().toTimeString())
            setMsgToDisplay("You have been signed out due to inactivity")
            setSession({})
            logout(true)
            //idleTimer.reset()
        }
        else
            console.log("onIdle ignored, was already logged out")
    }

    const idleTimer = useIdleTimer({
        onIdle,
        //onActive,
        //onAction,
        // keep all app instance on multiple browser tabs active, even if the user is only active in one tab
        crossTab: true,
        timeout: authOptions.idleBeforeLogoutSec * 1000, //msec
        throttle: 500
    })

    const CheckRefreshTokenNeeded = async () => {
        // while the user was not logged out because of being idle, check if the API token should be refreshed
        console.log(new Date().toTimeString(), `idle=${idleTimer.isIdle()} remaoning=${idleTimer.getRemainingTime()} lastActive= ${idleTimer.getLastActiveTime()} `)
        if (session.auth) {
            //console.log(new Date().toTimeString(), 'checking renew, auth:', session.auth);
            if (await refreshTokenIfNeeded()) {
                // refreshTokenIfNeeded returns true on successful refresh when the user data has changed
                // or user isdisabled/deleted
                // cause rerun, session will be correctly set at the top of the next instance of SessionProvider   
                setSession({})
            }
        }
    }

    // note: while the browser tab is in background, browser can throttle setTimeOut's time. In some configuration they might 
    // stop altogether, or emit a burst of functions run when the tab becomes active again.
    // The setInterval used here is not the native js one, but is implemented as running in a web-worker, which should not be stopped by 
    // any browser. Previous timer id is stored as a static property. timers must be restarted since an old timer function still sees the old context

    if (SessionProvider.timerId)
        wt_clearInterval(SessionProvider.timerId)

    SessionProvider.timerId = wt_setInterval(async () => { await CheckRefreshTokenNeeded() }, authOptions.pollForTokenRefreshSec * 1000)

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContext;